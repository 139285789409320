import { useState, useEffect, lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import EllipsisMenu from 'src/components/entryPage/ellipsisMenu/EllipsisMenu';
import Sidebar from 'src/components/entryPage/sidebar/Sidebar';

import styles from 'src/components/entryPage/EntryPage.module.css';
import { m } from 'src/util/cssUtil';
import egainLogo from 'src/img/egainLogo.png';

const NavButtons = lazy(() =>
  import(
    /* webpackChunkName: "NavButtons" */
    /* webpackPrefetch: true */
    'src/components/entryPage/sidebar/NavButtons'
  )
);

export default function EntryHeader() {
  const navigate = useNavigate();
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1000);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 550);
  const { i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    function handleResize() {
      setIsTablet(window.innerWidth < 1000);
      setIsMobile(window.innerWidth < 550);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={m(styles.headerContainer)}>
      <div className={m(styles.headerWrapper, "d-flex")}>
        <div
          className={m(
            styles.topBar,
            styles.primaryBox,
            'd-flex',
            'align-items-center',
            'justify-content-between'
          )}
        >
          <div className={m(styles.containerCommon, 'px-0')}>
            <a href="" onClick={() => navigate('/home')}>
              <img
                src={egainLogo}
                className={styles.headerLogo}
                alt="eGain Logo"
              />
            </a>
          </div>

          {isMobile ? (
            <EllipsisMenu />
          ) : isTablet ? (
            <div className="pe-4">
              <NavButtons />
            </div>
          ) : null}
        </div>

        <Sidebar className="d-flex">
          <Suspense>
            <div
              className={m(
                styles.topBar,
                styles.topSidebar,
                'd-flex',
                'align-items-center',
                'justify-content-end'
              )}
            >
              <NavButtons />
            </div>
          </Suspense>
        </Sidebar>
      </div>
    </div>
  );
}
