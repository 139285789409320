import { lazy } from 'react';
import { useLocation, Outlet } from "react-router";

import HomePage from 'src/pages/homepage/HomePage';

export default function Root() {
  const location = useLocation();

  return location.pathname === '/'
    ? <HomePage />
    : <Outlet />;
}