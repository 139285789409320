import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  status: 'unknown',
  message: null,
  redirectUrl: null,
  retryCount: 0,
};

const slice = createSlice({
  name: 'waiting',
  initialState,
  reducers: {
    redirectTenant(state, action) {
      state.status = action.payload.status;
      state.message = action.payload.message;
      state.redirectUrl = action.payload.redirectUrl;
    },
    setRetry(state,action){
      state.retryCount = action.payload
    },  
  },
});

const waitingSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    status: (state) => state[slice.name].status,
    message: (state) => state[slice.name].message,
    redirectUrl: (state) => state[slice.name].redirectUrl,
    retryCount: (state) => state[slice.name].retryCount,
  },
  rememberedKeys: [],
};

export default waitingSlice;
