import { createSlice } from '@reduxjs/toolkit';
import { is } from 'date-fns/locale';
import { set } from 'lodash';

const initialState = {
  customerId: '',
  subscriptionId: '',
  isTrialToProd: false,
  isPaymentFailure: false,
  isback: false,
  trialItems: {},
  id: '',
};

const slice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    updateCustomerId(state, action) {
      state.customerId = action.payload;
    },
    updateSubscriptionId(state, action) {
      state.subscriptionId = action.payload;
    },
    setIsTrialToProd(state, action) { // New action to update isTrialToProd state
      state.isTrialToProd = action.payload;
    },
    trialItems(state, action) {
      state.trialItems = action.payload; 
     },
     setIsPaymentFailure: (state, action) => {
      state.isPaymentFailure = action.payload;
    },
    setIsback: (state, action) => {
      state.isback = action.payload;
  },
  setId: (state, action) => {
    state.id = action.payload;
},
  }
});

const myAccountSlice = {
  initialState,
  reducers: {
    [slice.name]: slice.reducer,
  },
  actions: slice.actions,
  select: {
    customerId: (state) => state[slice.name].customerId,
    subscriptionId: (state) => state[slice.name].subscriptionId,
    isTrialToProd: (state) => state[slice.name].isTrialToProd,
    trialItems: (state) => state[slice.name].trialItems,
    isPaymentFailure: (state) => state[slice.name].isPaymentFailure,
    isback: (state) => state[slice.name].isback,
    id: (state) => state[slice.name].id,
  },
  rememberedKeys: [],
};

export default myAccountSlice;
