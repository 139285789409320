import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';

import styles from 'src/components/secondaryButton/SecondaryButton.module.css';
import { m } from 'src/util/cssUtil';

export default function SecondaryButton({ onClick, children, className, disableValue }) {
  return <Button
    type="button"
    variant="outline-primary"
    onClick={onClick}
    className={m(className, styles.secondaryButton)}
    disabled={disableValue}
  >
    {children}
  </Button>
}

SecondaryButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
}